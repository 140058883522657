import {
  globalGetService,
  globalPostService,
} from '../../../utils/globalApiServices';
import { Pagination } from '../../../utils/types';
import { BrandDetail, ExtVettingRecord } from './types';

export const getVettingRecords = async (brandUid: string) => {
  const response = await globalGetService(`csp/brands/${brandUid}/vettings`, {
    vettingStatus: 'ACTIVE,PENDING,UNSCORE,FAILED,EXPIRED',
  });
  if (response.ok) {
    return response.data as ExtVettingRecord[];
  }
  return [];
};

export const resend2faEmail = async (brandUid: string) => {
  const response = await globalPostService(`csp/brands/${brandUid}/2faEmail`);
  if (response.ok) {
    return brandUid;
  }
};

export const getBrands = async (query = {}) => {
  const response = await globalGetService('csp/brands', query);
  return response?.ok ? (response.data as Pagination<BrandDetail>) : undefined;
};

export const getBrandIdentityStatus = async () => {
  const response = await globalGetService('enums/brandIdentityStatus');
  return response.ok ? (response.data as string[]) : [];
};
