import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  queryCampaignPhoneNumbers,
  getCampaignPhoneNumbersOnly,
} from '../../apiServices';
import NumbersProvisionedListingRow from '../NumbersProvisionedListingRow';
import DownloadPhoneNumbersModal from '../DownloadPhoneNumbersModal';
import { Box, Button, Table } from 'portal-commons';
import { OsrPhoneAssociatedCampaign } from '../../types';
import { toastFlashMessage } from '../../../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-regular-svg-icons';
import { faGridRound } from '@fortawesome/pro-thin-svg-icons';

const useStyles = makeStyles({
  container: {
    marginBottom: '20px',
  },
  tabs: {
    display: 'flex',
    '& .tab-label': {
      border: '1px solid #C0CCD4',
      minWidth: '225px',
      height: '26px',
      padding: '9px 14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 600,
      color: '#49535D',
      backgroundColor: '#E5F0F4',
      cursor: 'pointer',
      '&:first-child': {
        paddingLeft: '20px',
      },
      '& img': {
        height: '18px',
        marginRight: '5px',
        filter: 'grayscale(1)',
      },
      '& svg': {
        marginRight: '5px',
      },
      '& .badge': {
        borderRadius: '4px',
        marginLeft: '24px',
        fontSize: '14px',
        fontWeight: 500,
        padding: '3px 6px',
        backgroundColor: '#FFFFFF',
      },
      '&.active': {
        color: '#00698F',
        fontWeight: 800,
        backgroundColor: '#FFFFFF',
        borderBottom: '1px solid #FFFFFF',
        position: 'relative',
        '& .badge': {
          color: '#49535D',
          marginLeft: '24px',
          fontSize: '14px',
          fontWeight: 500,
          padding: '3px 6px',
          backgroundColor: '#E6E7E7',
        },
      },
      '&.active img': {
        filter: 'grayscale(0)',
      },
      '& + .tab-label': {
        borderLeft: 0,
      },
    },
  },
  tabContents: {
    marginTop: '-1px',
    border: '1px solid #C0CCD4',
    padding: '16px 20px',
    overflowX: 'auto',
    '& .tableWrapper table tbody tr.empty-row': {
      height: '160px',
    },
    '& .tableWrapper table thead th:first-child': {
      width: '240px',
    },
    '& .tableWrapper table thead th:nth-child(2)': {
      width: '180px',
    },
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    '& p': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
    },
  },
  '@keyframes rotate': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  copyLink: {
    marginLeft: 5,
    fontWeight: 400,
    color: '#949495',
    cursor: 'pointer',
    textTransform: 'initial',
    textDecoration: 'underline',
    '&.disabled': {
      cursor: 'not-allowed',
    },
    '& svg': {
      marginLeft: 5,
      animation: '$rotate 2s linear infinite',
    },
  },
});

enum TabName {
  CURRENT = 'CURRENT',
  PREVIOUS = 'PREVIOUS',
}

interface Props {
  campaignUid: string;
}

const NumbersProvisioned: React.FC<Props> = ({ campaignUid }) => {
  const classes = useStyles();

  const emptyPagination: {
    records: OsrPhoneAssociatedCampaign[];
    page: number;
    recordsPerPage: number;
    totalRecords: number;
  } = {
    records: [],
    page: 1,
    recordsPerPage: 10,
    totalRecords: 0,
  };
  const [activeTab, setActiveTab] = useState(TabName.CURRENT);
  const [tableLoader, setTableLoader] = useState(false);
  const [currentNumbersInfo, setCurrentNumbersInfo] = useState(emptyPagination);
  const [previousNumbersInfo, setPreviousNumbersInfo] =
    useState(emptyPagination);
  const [openDownload, setOpenDownload] = useState(false);

  const generateCustomPhoneNumberHeader = (
    label: string,
    isDisabled = true
  ) => {
    const [loadingAllPhoneNumbers, setLoadingAllPhoneNumbers] = useState(false);
    const oldCopyMethod = (clipboardData: string) => {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', clipboardData);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        toastFlashMessage('Phone numbers copied to clipboard', 'success');
      } else {
        toastFlashMessage(`Failed to copy phone numbers to clipboard`, 'error');
      }
      document.body.removeChild(input);
    };

    const handleClick = async (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      const query = {
        current: activeTab === TabName.CURRENT,
      };
      setLoadingAllPhoneNumbers(true);
      const result = await getCampaignPhoneNumbersOnly(campaignUid, query);
      if (result.length > 0) {
        navigator.clipboard
          .writeText(result.join(','))
          .then(() => {
            toastFlashMessage('Phone numbers copied to clipboard', 'success');
          })
          .catch(() => {
            oldCopyMethod(result.join(','));
          });
      }
      setLoadingAllPhoneNumbers(false);
    };
    return (
      <Box>
        {label}
        <a
          className={`${classes.copyLink} ${isDisabled ? 'disabled' : ''}`}
          onClick={(e) => !isDisabled && handleClick(e)}
        >
          copy all
          {loadingAllPhoneNumbers && <FontAwesomeIcon icon={faLoader} />}
        </a>
      </Box>
    );
  };

  const getHeadRows = (tabName: TabName) => {
    const isDisabled =
      tabName === TabName.CURRENT
        ? currentNumbersInfo.totalRecords === 0
        : previousNumbersInfo.totalRecords === 0;
    return [
      {
        id: 'phoneNumber',
        label: `${tabName} PHONE NUMBERS`,
        sortable: false,
        customContent: generateCustomPhoneNumberHeader(
          `${tabName} PHONE NUMBERS`,
          isDisabled
        ),
      },
      { id: 'nnid', label: 'NNID', sortable: false },
      { id: 'startTimestamp', label: 'PROVISIONED ON', sortable: false },
    ];
  };

  const getCampaignPhoneNumbers = async (isCurrent = true) => {
    setTableLoader(true);
    const query = {
      current: isCurrent,
    };
    const result = await queryCampaignPhoneNumbers(campaignUid, query);
    if (result) {
      if (isCurrent) {
        setCurrentNumbersInfo(result);
      } else {
        setPreviousNumbersInfo(result);
      }
    }
    setTableLoader(false);
  };

  useEffect(() => {
    getCampaignPhoneNumbers(true);
    getCampaignPhoneNumbers(false);
  }, []);

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <div className={classes.tabs}>
            <div
              data-testid="currentNumberProvisionedTab"
              onClick={() => setActiveTab(TabName.CURRENT)}
              className={`tab-label ${
                activeTab === TabName.CURRENT ? 'active' : ''
              }`}
            >
              <FontAwesomeIcon icon={faGridRound} />
              Current Numbers Provisioned
              <span className="badge">{currentNumbersInfo.totalRecords}</span>
            </div>
            <div
              data-testid="previousNumberProvisionedTab"
              onClick={() => setActiveTab(TabName.PREVIOUS)}
              className={`tab-label ${
                activeTab === TabName.PREVIOUS ? 'active' : ''
              }`}
            >
              <FontAwesomeIcon icon={faGridRound} />
              Previous Numbers Provisioned
              <span className="badge">{previousNumbersInfo.totalRecords}</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.tabContents}>
          <div className={classes.contentHeader}>
            <p data-testid="previousNumberProvisionedTabContent">
              The number represented here are only the most recent 10
              Provisioned Phone Numbers. To view all of the{' '}
              {activeTab === TabName.CURRENT ? 'current' : 'previous'}{' '}
              Provisioned Phone Numbers use the download button.
            </p>
            <Button
              shape="square"
              size="small"
              onClick={() => setOpenDownload(true)}
              data-testid="downloadPhoneNumbersButton"
              disabled={
                currentNumbersInfo.totalRecords === 0 &&
                previousNumbersInfo.totalRecords === 0
              }
            >
              Download
            </Button>
          </div>

          {activeTab === TabName.CURRENT ? (
            <Table
              className="phone-number-table"
              testId="currentPhoneNumberTable"
              disableHover
              key="CurrentPhoneNumberTable"
              loading={tableLoader}
              emptyState={`no current phone number to view`}
              headRows={getHeadRows(TabName.CURRENT)}
              tableData={currentNumbersInfo.records.map((record, idx) => {
                return (
                  <NumbersProvisionedListingRow
                    key={`row-${idx}`}
                    data={record}
                  />
                );
              })}
            />
          ) : (
            <Table
              className="phone-number-table"
              testId="previousPhoneNumberTable"
              disableHover
              key="PreviousPhoneNumberTable"
              loading={tableLoader}
              emptyState={`no previous phone number to view`}
              headRows={getHeadRows(TabName.PREVIOUS)}
              tableData={previousNumbersInfo.records.map((record, idx) => {
                return (
                  <NumbersProvisionedListingRow
                    key={`row-${idx}`}
                    data={record}
                  />
                );
              })}
            />
          )}
        </Grid>
      </Grid>
      <DownloadPhoneNumbersModal
        open={openDownload}
        handleClose={() => setOpenDownload(false)}
        campaignUid={campaignUid}
      />
    </>
  );
};

export default NumbersProvisioned;
