import React from 'react';
import { Flex } from 'portal-commons';

export const userCreateRoleTooltipTemplate = (
  <Flex sx={{ flexDirection: 'column', gap: 'xs', fontSize: 'H200' }}>
    <p>
      <b>Viewer</b>: Can view campaigns, brands, resellers, and the CSP profile.
      No access to Integrations page. Cannot apply for external vetting or
      manage users.
    </p>
    <p>
      <b>Reviewer</b>: Can view campaigns, brands, resellers, and the CSP
      profile. Able to accept or decline shared campaigns and elect an upstream
      CNP. No access to Integrations page. Cannot apply for external vetting or
      manage users.
    </p>
    <p>
      <b>User</b>: Can view, create, and edit campaigns, brands, and resellers.
      Can view the CSP profile. Full access to campaign sharing. Can apply for
      external vetting and access Integrations page. Cannot manage users.
    </p>
    <p>
      <b>Manager</b>: Full access to view, create, and edit campaigns, brands,
      resellers, and CSP profile information. Full access to campaign sharing.
      Can apply for external vetting and access Integrations page. Can add and
      edit users.
    </p>
  </Flex>
);

export const MAX_EMAIL_COUNT = 5;
export const MAX_EMAIL_LENGTH = 100;
