export const DEV_BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : `https://csp-portal-api-dev.campaignregistry.com/api/v1/`;
export const OKTA_BASE_URL = process.env.REACT_APP_OKTA_BASE
  ? process.env.REACT_APP_OKTA_BASE
  : `https://dev-752242.okta.com/`;
export const S3_ASSETS_PATH = process.env.REACT_APP_S3_ASSETS_PATH
  ? process.env.REACT_APP_S3_ASSETS_PATH
  : 'http://static-dev.campaignregistry.com/csp';
export const OKTA_ISSUER_URL = process.env.REACT_APP_OKTA_AUTH_SERVER
  ? process.env.REACT_APP_OKTA_AUTH_SERVER
  : 'https://dev-752242.okta.com/oauth2/aus1p9e31v5n5LMW0357';
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID
  ? process.env.REACT_APP_OKTA_CLIENT_ID
  : `0oa1p1ehz97O9c5Cb357`;
export const DIRECTUS_TOKEN = process.env.REACT_APP_DIRECTUS_TOKEN
  ? process.env.REACT_APP_DIRECTUS_TOKEN
  : '2At81EDrhA4eM5Qlf96WBvDv';
export const GOOGLE_RECAPTCHA_SITE_KEY = process.env
  .REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
  ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
  : '6LcwRCYaAAAAAJcrI20yKbz9O4Zgd6S_ErRrt6tX';
export const DATADOG_APPLICATION_ID = process.env
  .REACT_APP_DATADOG_APPLICATION_ID
  ? process.env.REACT_APP_DATADOG_APPLICATION_ID
  : 'dd524889-1a48-4564-bb00-f08e892a5a81';
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  ? process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  : 'pubbd48e6d8bc30230d235ec32012a72511';
export const DATADOG_ENV = process.env.REACT_APP_DATADOG_ENV
  ? process.env.REACT_APP_DATADOG_ENV
  : 'local';
// eslint-disable-next-line @typescript-eslint/no-var-requires
export const PACKAGE_JSON_VERSION = require('../../package.json').version;

export const APP_VERSION = '1.0.2';

// DEV Portal
// 'https://csp-portal-api-dev.campaignregistry.com/api/v1/';
// 'https://dev-752242.okta.com/'
// https://dev-752242.okta.com/oauth2/aus1p9e31v5n5LMW0357
// '0oa1p1ehz97O9c5Cb357'

export const FREE_EMAILS_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'hotmail.co.uk',
  'hotmail.fr',
  'msn.com',
  'yahoo.fr',
  'wanadoo.fr',
  'orange.fr',
  'comcast.net',
  'yahoo.co.uk',
  'yahoo.com.br',
  'yahoo.co.in',
  'live.com',
  'rediffmail.com',
  'free.fr',
  'gmx.de',
  'web.de',
  'yandex.ru',
  'ymail.com',
  'libero.it',
  'outlook.com',
  'uol.com.br',
  'bol.com.br',
  'mail.ru',
  'cox.net',
  'hotmail.it',
  'sbcglobal.net',
  'sfr.fr',
  'live.fr',
  'verizon.net',
  'live.co.uk',
  'googlemail.com',
  'yahoo.es',
  'ig.com.br',
  'live.nl',
  'bigpond.com',
  'terra.com.br',
  'yahoo.it',
  'neuf.fr',
  'yahoo.de',
  'alice.it',
  'rocketmail.com',
  'att.net',
  'laposte.net',
  'facebook.com',
  'bellsouth.net',
  'yahoo.in',
  'hotmail.es',
  'charter.net',
  'yahoo.ca',
  'yahoo.com.au',
  'rambler.ru',
  'hotmail.de',
  'tiscali.it',
  'shaw.ca',
  'yahoo.co.jp',
  'sky.com',
  'earthlink.net',
  'optonline.net',
  'freenet.de',
  't-online.de',
  'aliceadsl.fr',
  'virgilio.it',
  'home.nl',
  'qq.com',
  'telenet.be',
  'me.com',
  'yahoo.com.ar',
  'tiscali.co.uk',
  'yahoo.com.mx',
  'voila.fr',
  'gmx.net',
  'mail.com',
  'planet.nl',
  'tin.it',
  'live.it',
  'ntlworld.com',
  'arcor.de',
  'yahoo.co.id',
  'frontiernet.net',
  'hetnet.nl',
  'live.com.au',
  'yahoo.com.sg',
  'zonnet.nl',
  'club-internet.fr',
  'juno.com',
  'optusnet.com.au',
  'blueyonder.co.uk',
  'bluewin.ch',
  'skynet.be',
  'sympatico.ca',
  'windstream.net',
  'mac.com',
  'centurytel.net',
  'chello.nl',
  'live.ca',
  'aim.com',
  'bigpond.net.au',
];
export const US_STATE_LIST = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Lousiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const BRAND_RELATIONSHIP_SCORE = [
  {
    img: `${S3_ASSETS_PATH}/images/basic-accounts.svg`,
    label: `Basic accounts`,
    description: `no business history with the CSP`,
    value: 1,
    icon: `${S3_ASSETS_PATH}/images/br-1.svg`,
  },
  {
    img: `${S3_ASSETS_PATH}/images/small-accounts.svg`,
    label: `Small accounts`,
    description: `or some business history with the CSP`,
    value: 2,
    icon: `${S3_ASSETS_PATH}/images/br-2.svg`,
  },
  {
    img: `${S3_ASSETS_PATH}/images/medium-accounts.svg`,
    label: `Medium accounts`,
    description: `with good standing with the CSP and solid business history`,
    value: 3,
    icon: `${S3_ASSETS_PATH}/images/br-3.svg`,
  },
  {
    img: `${S3_ASSETS_PATH}/images/large-accounts.svg`,
    label: `Large accounts`,
    description: `with a dedicated account manager, highly trusted`,
    value: 4,
    icon: `${S3_ASSETS_PATH}/images/br-4.svg`,
  },
  {
    img: `${S3_ASSETS_PATH}/images/key-accounts.svg`,
    label: `Key accounts`,
    description: `with strategic value and a dedicated account team`,
    value: 5,
    icon: `${S3_ASSETS_PATH}/images/br-5.svg`,
  },
];

export const ENTITY_TYPES = [
  {
    label: 'Publicly Traded Company',
    value: 'PUBLIC_PROFIT',
  },
  {
    label: 'Private Company',
    value: 'PRIVATE_PROFIT',
  },
  {
    label: 'Non-Profit Organization',
    value: 'NON_PROFIT',
  },
  {
    label: 'Government',
    value: 'GOVERNMENT',
  },
  {
    label: 'Sole Proprietor',
    value: 'SOLE_PROPRIETOR',
  },
];
export const ALPHABETS = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
  9: 'J',
  10: 'K',
  11: 'L',
  12: 'M',
  13: 'N',
  14: 'O',
  15: 'P',
  16: 'Q',
  17: 'R',
  18: 'S',
  19: 'T',
  20: 'U',
  21: 'V',
  22: 'W',
  23: 'X',
  24: 'Y',
  25: 'Z',
};

export const OPEN_CANADIAN_BRAND_NOTICE = 'OpenCanadianBrandNotice';

export const US_DATE_FORMAT = 'MM/dd/yyyy';
export const US_DATE_FORMAT_WITH_TIME = 'MM/DD/YYYY HH:mm';

export const mnoNameOrder = [
  'AT&T',
  'T-Mobile',
  'Verizon Wireless',
  'US Cellular',
  'ClearSky',
  'Interop',
];
