import React from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import parse from 'html-react-parser';

export default function CustomizableConfirmationDialog(props) {
  const { open, loader, content, submitText, handleClick } = props;
  return (
    <Dialog
      open={open}
      onClose={() => handleClick(false)}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth={true}
      id="successModal"
      className={`brand-confirmation-dialog`}
      data-testid="confirmationDialog"
    >
      <DialogContent
        className={`brand-confirmation-dialog-content text-center`}
      >
        <Grid container>
          <Grid item xs={12} className="title">
            <p className="paragraph" data-testid="confirmationDialogContent">
              {parse(content)}
            </p>
          </Grid>
          <Grid item xs={12} className="footer">
            <li>
              <p>
                <a
                  className={'secondary-btn'}
                  onClick={() => handleClick(false)}
                  data-testid="confirmationDialogCancelButton"
                >
                  Cancel
                </a>
              </p>
            </li>
            <li>
              <p>
                <a
                  className={loader ? 'primary-btn disabled' : 'primary-btn'}
                  onClick={() => handleClick(true)}
                  data-testid="confirmationDialogSubmitButton"
                >
                  {loader
                    ? 'Processing...'
                    : submitText
                    ? submitText
                    : 'I Agree'}
                </a>
              </p>
            </li>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
