import { ENTITY_TYPES } from '../../../constants';
import { convertTimeWithTimezone } from '../../../utils/time';

export const booleanValueToText = (value?: boolean) =>
  typeof value === 'boolean' ? (value ? 'Yes' : 'No') : 'N/A';
export const currentAndMaxValuesToText = (current?: number, max?: number) =>
  typeof current === 'number' && typeof max === 'number'
    ? `${current}/${max}`
    : 'N/A';
export const dateValueToText = (value?: string) =>
  value ? convertTimeWithTimezone(value, undefined, 'MM/DD/YYYY') : 'N/A';
export const entityTypeValueToText = (value?: string) =>
  value
    ? ENTITY_TYPES.find((type) => type.value === value)?.label ?? 'N/A'
    : 'N/A';
export const numberValueToText = (value?: number) =>
  typeof value === 'number' ? value : 'N/A';
export const stringValueToText = (value?: string) => value || 'N/A';
export const roleEnumToLabel = (str: string) => {
  const [, second = ''] = str.split('_');
  return second.charAt(0).toUpperCase() + second.slice(1).toLowerCase();
};
