import {
  SideNavigation as BaseSideNavigation,
  usePermission,
} from 'portal-commons';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  faHome,
  faBullhorn,
  faTags,
  faCalendarDay,
  faGearCode,
  faArrowsRepeat,
  faBookBookmark,
} from '@fortawesome/pro-regular-svg-icons';

import { useAuthContext } from '../../contexts';

export function SideNavigation() {
  const { user, role } = useAuthContext();
  const location = useLocation();
  const { hasPermission } = usePermission();
  const inActiveCSPAccount = location.pathname === '/user/csp';
  const inRegistrationFlow =
    location.pathname === '/user/csp/create' ||
    location.pathname === '/user/csp/status';

  const navLink = useMemo(() => {
    const link = [
      {
        title: 'Dashboard',
        icon: faHome,
        pathname: '/dashboard',
      },
      {
        title: 'My Campaigns',
        icon: faBullhorn,
        pathname: '/campaigns',
      },
      {
        title: 'CNP Campaigns',
        icon: faBullhorn,
        pathname: '/cnp-campaigns',
      },
      {
        title: 'Brands',
        icon: faTags,
        pathname: '/brands',
      },
      {
        title: 'Resellers',
        icon: faArrowsRepeat,
        pathname: '/resellers',
      },
      {
        title: 'Events',
        icon: faCalendarDay,
        pathname: '/events',
      },
      {
        title: 'Knowledge Base',
        icon: faBookBookmark,
        pathname: '/knowledge-base',
      },
    ];

    if (hasPermission('integrations.view')) {
      link.push({
        title: 'Integrations',
        icon: faGearCode,
        pathname: '/integrations',
      });
    }
    return link;
  }, [role]);

  return (
    <BaseSideNavigation
      portal="csp"
      items={inRegistrationFlow ? navLink : inActiveCSPAccount ? [] : navLink}
      location={location}
      lastLoginTime={user?.lastLogin}
      linkComponent={Link}
      className={`side-navigation ${inRegistrationFlow ? 'registration' : ''}`}
    />
  );
}

export default SideNavigation;
