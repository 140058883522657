import { PermissionConfig } from 'portal-commons';
import { Role } from '../types/user';

const AllRoles = Object.values(Role);

const Permissions: PermissionConfig = {
  profile: {
    view: { roles: AllRoles },
    editEmail: { roles: [Role.Manager] },
    addUser: { roles: [Role.Manager] },
    editUser: { roles: [Role.Manager] },
    deleteUser: { roles: [Role.Manager] },
    toggleMfa: { roles: [Role.Manager] },
  },
  campaignList: {
    addCampaign: { roles: [Role.Manager, Role.User] },
  },
  cnpCampaignList: {
    view: { roles: AllRoles },
    // elect and re-elect are the same permission
    elect: { roles: [Role.Manager, Role.User, Role.Reviewer] },
    decline: { roles: [Role.Manager, Role.User, Role.Reviewer] },
  },
  brandList: {
    addBrand: { roles: [Role.Manager, Role.User] },
  },
  resellerList: {
    addReseller: { roles: [Role.Manager, Role.User] },
    editReseller: { roles: [Role.Manager, Role.User] },
    deleteReseller: { roles: [Role.Manager, Role.User] },
  },
  integrations: {
    view: { roles: [Role.Manager, Role.User] },
    addApi: { roles: [Role.Manager, Role.User] },
    editApi: { roles: [Role.Manager, Role.User] },
    deleteApi: { roles: [Role.Manager, Role.User] },
  },
  campaignDetail: {
    viewMoreActions: { roles: [Role.Manager, Role.User] },
    autoRenewalToggle: { roles: [Role.Manager, Role.User] },
    editCampaignAttributes: { roles: [Role.Manager, Role.User] },
    editCampaignDetails: { roles: [Role.Manager, Role.User] },
    addCtaMultimedia: { roles: [Role.Manager, Role.User] },
    deleteCtaMultimedia: { roles: [Role.Manager, Role.User] },
    editSampleMessages: { roles: [Role.Manager, Role.User] },
    addSampleMultimedia: { roles: [Role.Manager, Role.User] },
    deleteSampleMultimedia: { roles: [Role.Manager, Role.User] },
    electCnp: { roles: [Role.Manager, Role.User, Role.Reviewer] },
    addReseller: { roles: [Role.Manager, Role.User] },
  },
  brandDetail: {
    viewMoreActions: { roles: [Role.Manager, Role.User] },
    editCompanyName: { roles: [Role.Manager, Role.User] },
    appealIdentity: { roles: [Role.Manager, Role.User] }, // Hidden to Sole Proprietor
    resubmitBrand: { roles: [Role.Manager, Role.User] },
    editCompanyDetails: { roles: [Role.Manager, Role.User] },
    send2faEmail: { roles: [Role.Manager, Role.User] },
    applyForVetting: { roles: [Role.Manager, Role.User] },
    importVetting: { roles: [Role.Manager, Role.User] },
    appealVetting: { roles: [Role.Manager, Role.User] },
  },
};

export default Permissions;
