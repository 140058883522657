import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../../shared_elements/actions';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  getAllUsecasesTypes,
  fetchBrandSuggestions,
  getCampaigns,
  fetchResellerSuggestions,
  downloadCampaignsAsCsv,
} from '../apis';
import { S3_ASSETS_PATH } from '../../../../constants';
import Loader from '../../../../shared_elements/containers/Loader';
import { CampaignListingRow, DownloadLimitedModal } from '../components';
import {
  Button,
  RoleGuard,
  Table,
  FilterType,
  TableFilter,
  WithFiltersConfig,
  FilterConfig,
  withPermission,
} from 'portal-commons';
import '../../../../assets/styles/campaign-listing-module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faArrowDownToLine,
} from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { withFilters } from '../../../../hocs';
import { fieldValidation } from '../../../../utils/validator';
import { CampaignFilterErrorCode as FilterErrorCode } from '../../index';
import {
  AdditionalFilters,
  CombinedFilters,
  DEFAULT_ADDITIONAL_FILTERS,
  Pagination,
  SelectItem,
  UsecaseType,
} from '../../../../utils/types';
import { CspCampaign } from '../types';
import { generateAndDownloadFile } from '../../../../utils/download';
import { toastFlashMessage } from '../../../../utils';

const FILTER_CONFIG = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Enter campaign id',
    width: 150,
  },
  usecase: {
    type: FilterType.Dropdown,
    label: 'Use-Case',
    placeholder: 'Select use-case',
    options: [],
    width: 230,
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  brandName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Enter brand name',
    suggestions: [],
    width: 260,
  },
  resellerName: {
    type: FilterType.Text,
    label: 'Reseller Name',
    placeholder: 'Enter reseller name',
    suggestions: [],
    width: 260,
  },
  referenceId: {
    type: FilterType.Text,
    label: 'Reference ID',
    placeholder: 'Enter reference id',
    width: 150,
    error: false,
    helperText: '',
  },
  status: {
    type: FilterType.Dropdown,
    label: 'TCR Status',
    placeholder: 'Select TCR status',
    options: [
      {
        label: 'Active',
        value: 'ACTIVE',
      },
      {
        label: 'Deactivated',
        value: 'EXPIRED',
      },
    ],
    width: 230,
  },
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'brandName', label: 'BRAND NAME', sortable: false },
  { id: 'usecase', label: 'USE-CASE', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'resellerName', label: 'RESELLER NAME', sortable: false },
  { id: 'status', label: 'TCR STATUS', sortable: true },
];

const useStyles = makeStyles({
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
    fontSize: '27px',
    lineHeight: '32px',
    color: '#19262A',
  },
  note: {
    marginTop: 5,
    marginLeft: '12px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#666E71',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
  },
  spacing: {
    flex: 1,
  },
});

export interface ErrorCodeType {
  [name: string]: {
    [key: number | string]: string | boolean | number;
  };
}

interface ExtendPagination<T> extends Pagination<T> {
  displayTotal: number;
}

const EmptyPaginationData: ExtendPagination<CspCampaign> = {
  records: [],
  page: 1,
  recordsPerPage: 10,
  totalRecords: 0,
  displayTotal: 0,
};

const fetchBrandNameSuggestions = async (query = {}) => {
  const suggestions = await fetchBrandSuggestions(query);
  return suggestions.map((suggestion) => suggestion.brandName);
};
const fetchResellerNameSuggestions = async (query = {}) => {
  const suggestions = await fetchResellerSuggestions(query);
  return suggestions.map((suggestion) => suggestion.companyName);
};

interface Props extends WithFiltersConfig {
  filter: {
    configs: Record<string, FilterConfig>;
    candidateFilters: Record<string, string>;
    appliedFilters: Record<string, string>;
    handleEdit: (value: Record<string, string>) => void;
    handleApply: (value: Record<string, string>) => void;
    setOptions: (field: string, options: SelectItem[]) => void;
    updateConfigObject: (newConfigs: Record<string, FilterConfig>) => void;
  };
}

const CampaignsListing: React.FC<Props> = ({ filter }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const setLoadingMessage = (message: string) => {
    dispatch({ type: SET_LOADING_MESSAGE, payload: message });
  };
  const clearLoadingMessage = () => {
    dispatch({ type: CLEAR_LOADING_MESSAGE });
  };
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(true);
  const [noCampaignsFound, setNoCampaignsFound] = useState(false);
  const [downloadLimited, setDownloadLimited] = useState(false);
  const [filtersShown, setFiltersShown] = useState(false);
  const [campaignInfo, setCampaignInfo] =
    useState<ExtendPagination<CspCampaign>>(EmptyPaginationData);
  const [additionalFilters, setAdditionalFilters] = useState<AdditionalFilters>(
    DEFAULT_ADDITIONAL_FILTERS
  );

  const fetchFilterOptions = async () => {
    const { setOptions } = filter;
    const usecaseTypes = await getAllUsecasesTypes();
    setOptions(
      'usecase',
      usecaseTypes.map((item: UsecaseType) => ({
        label: item.displayName,
        value: item.id,
      }))
    );
  };

  useEffect(() => {
    document.title = 'The Campaign Registry - Campaigns';
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    if (location.search) {
      const params = parseSearchParams(location.search);
      fetchCampaignsList(params);
    } else {
      fetchCampaignsList({ ...additionalFilters });
    }
  }, [location.search]);

  const parseSearchParams = (params = ''): CombinedFilters => {
    let newAdditionalFilters = { ...additionalFilters };
    const { appliedFilters } = filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      newAdditionalFilters = DEFAULT_ADDITIONAL_FILTERS;
    } else if (queries.sortField || queries.ascendingOrder) {
      newAdditionalFilters = {
        sortField: queries.sortField as string,
        ascendingOrder: JSON.parse(queries.ascendingOrder as string),
      };
    }

    setAdditionalFilters(newAdditionalFilters);

    return {
      ...newAdditionalFilters,
      ...appliedFilters,
      page: (queries?.page as string) ?? 1,
    };
  };

  const fetchCampaignsList = async (queries: CombinedFilters) => {
    setTableLoader(true);
    const { appliedFilters } = filter;
    const campaignData = await getCampaigns(queries);
    if (campaignData) {
      const updatedCampaignInfo = {
        ...campaignData,
        totalRecords: Math.min(campaignData.totalRecords, 10000),
        displayTotal: campaignData.totalRecords,
      };
      setLoader(false);
      setCampaignInfo(updatedCampaignInfo);
      setNoCampaignsFound(
        updatedCampaignInfo.totalRecords === 0 &&
          Object.keys(appliedFilters).length === 0
      );

      if (
        updatedCampaignInfo.records.length === 0 &&
        updatedCampaignInfo.totalRecords > 0 &&
        updatedCampaignInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(
          updatedCampaignInfo.totalRecords / updatedCampaignInfo.recordsPerPage
        );
        setLoader(true);
        writeQueryString({ ...queries, page: lastPageNo });
        return;
      }
    }
    setTableLoader(false);
  };

  const handleChangePage = (newPage: number) => {
    const { appliedFilters } = filter;
    writeQueryString({
      ...appliedFilters,
      ...additionalFilters,
      page: newPage,
    });
  };

  const createSortHandler = (sortField: string, ascendingOrder: boolean) => {
    const { appliedFilters } = filter;
    const updatedAdditionalFilters = {
      ...additionalFilters,
      ascendingOrder,
      sortField,
    };
    setAdditionalFilters(updatedAdditionalFilters);

    if (campaignInfo.totalRecords) {
      writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: campaignInfo.page,
        sortField,
        ascendingOrder,
      });
    }
  };

  const resetFilterConfigsError = (newValues: Record<string, string>) => {
    const { configs, candidateFilters, updateConfigObject } = filter;
    const tempFilterConfigs = { ...configs };
    Object.keys(newValues).forEach((key) => {
      if (
        tempFilterConfigs[key]?.error &&
        newValues[key] !== candidateFilters[key]
      ) {
        tempFilterConfigs[key].error = false;
        tempFilterConfigs[key].helperText = '';
      }
    });
    updateConfigObject(tempFilterConfigs);
  };

  const validateFilters = (values: Record<string, string>) => {
    const { configs, updateConfigObject } = filter;
    const tempFilterConfigs = { ...configs };
    Object.keys(values).forEach((key) => {
      if (key in FilterErrorCode) {
        const code = fieldValidation({
          ...(FilterErrorCode as ErrorCodeType)[`${key}Obj`],
          fieldval: values[key],
        });
        if (code !== 0) {
          tempFilterConfigs[key].error = true;
          tempFilterConfigs[key].helperText = String(
            (FilterErrorCode as ErrorCodeType)[key][code]
          );
        }
      }
    });
    updateConfigObject(tempFilterConfigs);
    return Object.keys(values).every((key) => !tempFilterConfigs[key].error);
  };

  const handleCandidateFiltersChange = (values: Record<string, string>) => {
    resetFilterConfigsError(values);
    filter.handleEdit(values);
  };

  const handleAppliedFiltersChange = (values: Record<string, string>) => {
    if (!validateFilters(values)) {
      return;
    }

    if (Object.keys(values).length === 0) {
      resetFilterConfigsError(
        filter.configs as unknown as Record<string, string>
      );
    }
    filter.handleApply(values);
  };

  const writeQueryString = (searchParams: CombinedFilters) => {
    history.push({
      search: `?${queryString.stringify(searchParams, { encode: true })}`,
    });
  };

  const handleDownload = async () => {
    setLoadingMessage('We are generating the document');
    const params = parseSearchParams(location.search);
    if ('page' in params) {
      delete params.page;
    }
    const response = await downloadCampaignsAsCsv(params);
    if (response) {
      const file = new File([response], 'my-campaigns.csv');
      generateAndDownloadFile(file);
    } else {
      toastFlashMessage('Failed to download event history', 'error');
    }
    clearLoadingMessage();
  };

  return (
    <>
      <section
        className="campaigns-listing-section"
        style={{ padding: '0px' }}
        data-testid="campaignListing"
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            {!noCampaignsFound ? (
              <Container maxWidth={false} style={{ padding: '0px' }}>
                <div className={classes.title}>
                  <span>
                    {!loader && !noCampaignsFound
                      ? `${campaignInfo.displayTotal} Campaigns`
                      : 'Campaigns'}
                  </span>
                  <span className={classes.note}>
                    Results capped at first 10,000 records
                  </span>
                </div>
                <div className={classes.buttons}>
                  <Button
                    color="secondary"
                    onClick={() => setFiltersShown(!filtersShown)}
                    data-testid="tableAddFilterButton"
                  >
                    <FontAwesomeIcon icon={faFilter} />
                    <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
                  </Button>
                  <Button
                    variant="outline"
                    color="secondary"
                    onClick={() => {
                      if (campaignInfo.displayTotal > 10000) {
                        setDownloadLimited(true);
                      } else {
                        handleDownload();
                      }
                    }}
                    data-testid="tableDownloadButton"
                  >
                    <FontAwesomeIcon icon={faArrowDownToLine} />
                    Download
                  </Button>
                  <div className={classes.spacing} />
                  <RoleGuard feature="campaignList.addCampaign">
                    <Button
                      variant="outline"
                      onClick={() => history.push('/campaign/create')}
                      data-testid="campaignListingAddButton"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      Add Campaign
                    </Button>
                  </RoleGuard>
                </div>
                {filtersShown && (
                  <Grid container style={{ marginBottom: 12 }}>
                    <TableFilter
                      configs={filter.configs}
                      candidateValues={filter.candidateFilters}
                      appliedValues={filter.appliedFilters}
                      onCandidateValuesChange={(
                        values: Record<string, string>
                      ) => handleCandidateFiltersChange(values)}
                      onAppliedValuesChange={(values: Record<string, string>) =>
                        handleAppliedFiltersChange(values)
                      }
                    />
                  </Grid>
                )}
                <Grid container justifyContent="center" spacing={0}>
                  <Table
                    testId="campaignsListingListingTableNew"
                    loading={tableLoader}
                    emptyState="no campaigns to view"
                    handleChangePage={handleChangePage}
                    createSortHandler={createSortHandler}
                    filter={additionalFilters as CombinedFilters}
                    headRows={headRows}
                    tableData={campaignInfo.records.map((record) => (
                      <CampaignListingRow data={record} key={record.uid} />
                    ))}
                    pagination={{
                      page: campaignInfo.page,
                      rowsPerPage: campaignInfo.recordsPerPage,
                      totalRecords: campaignInfo.totalRecords,
                    }}
                  />
                </Grid>
              </Container>
            ) : (
              <Container maxWidth={false} style={{ padding: '0px' }}>
                <Grid
                  container
                  className="no-campaigns-found"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item className="text-center">
                    <div className="outer-wrapper">
                      <img
                        src={`${S3_ASSETS_PATH}/images/no-campaigns-found.svg`}
                        alt=""
                      />
                      <h3 className="heading1">Please register a Campaign</h3>
                      <p className="heading1">No Campaigns found</p>
                    </div>
                    <RoleGuard feature="campaignList.addCampaign">
                      <div className="bottom-blk">
                        <Link
                          to="/campaign/create"
                          className="primary-btn"
                          data-testid="campaignsListingRegisterACampaignButton"
                        >
                          Register a Campaign
                        </Link>
                      </div>
                    </RoleGuard>
                  </Grid>
                </Grid>
              </Container>
            )}{' '}
          </>
        )}
      </section>
      <DownloadLimitedModal
        open={downloadLimited}
        onClose={() => setDownloadLimited(false)}
        onSubmit={() => {
          setDownloadLimited(false);
          handleDownload();
        }}
      />
    </>
  );
};

export default withPermission(
  withFilters(CampaignsListing, {
    configs: FILTER_CONFIG,
    loaders: {
      suggestion: {
        brandName: {
          minLength: 2,
          load: (value: string) => fetchBrandNameSuggestions({ prefix: value }),
        },
        resellerName: {
          minLength: 2,
          load: (value: string) =>
            fetchResellerNameSuggestions({ companyNamePrefix: value }),
        },
      },
    },
  })
);
