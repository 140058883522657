import React from 'react';
import ExpansionList from '../../../../ui_elements/ExpansionList';
import { ALPHABETS } from '../../../../constants';
import { checkDisplayStatus } from '../utils';

export default function DocSummaryNew(props) {
  const calculateOuterFrameHeight = () => {
    const headerHeight = document.querySelector('header')
      ? document.querySelector('header').getBoundingClientRect().height
      : 60;
    const titleHeight = document.querySelector('.doc-summary-blk .title')
      ? document
          .querySelector('.doc-summary-blk .title')
          .getBoundingClientRect().height
      : 40;
    const contentPanel = document.querySelector(
      '.doc-summary-blk .content-blk'
    );
    const contentPanelStyle = contentPanel
      ? getComputedStyle(
          document.querySelector('.doc-summary-blk .content-blk')
        )
      : { paddingTop: '20px', paddingBottom: '0' };
    const tabPanelVerticalPadding =
      parseInt(contentPanelStyle.paddingTop.replace('px', ''), 10) +
      parseInt(contentPanelStyle.paddingBottom.replace('px', ''), 10);
    const footerHeight = document.querySelector('.footer-hoc-block')
      ? document.querySelector('.footer-hoc-block').getBoundingClientRect()
          .height
      : 60;
    return headerHeight + titleHeight + tabPanelVerticalPadding + footerHeight;
  };
  const outerFrameHeight = calculateOuterFrameHeight();
  const { activeContent = {} } = props;
  return (
    <div className="doc-summary-blk" data-testid="docSummary">
      <div className="title">
        <h3 className="heading1">
          {activeContent.title && activeContent.title.toUpperCase()}
        </h3>
      </div>
      <div
        className="content-blk"
        style={{ height: window.innerHeight - outerFrameHeight }}
      >
        <ul className="list-unstyled">
          {activeContent.sections &&
            activeContent.sections
              .filter((item) => checkDisplayStatus(item.status))
              .map((item, index) => (
                <li key={index}>
                  {item.subsections && item.subsections.length > 0 ? (
                    <ExpansionList docflag="true" title={item.title}>
                      <ul className="subsection-list">
                        {item.subsections
                          .filter((sub) => checkDisplayStatus(sub.status))
                          .map((sub, subIndex) => (
                            <li key={subIndex}>
                              <a href={`#${index + 1}${ALPHABETS[subIndex]}`}>
                                {sub.title}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </ExpansionList>
                  ) : (
                    <a href={`#${index + 1}`}>{item.title}</a>
                  )}
                </li>
              ))}
        </ul>
      </div>
    </div>
  );
}
