import { AttachmentFolder } from './enums';
import {
  Attachment,
  BrandSuggestion,
  CspCampaign,
  FileFormats,
  PartnerCampaign,
  ResellerSuggestion,
} from './types';
import {
  globalDeleteService,
  globalGetService,
  globalPostService,
  globalPutService,
} from '../../../utils/globalApiServices';
import { Pagination, UsecaseType, Cnp, Mno } from '../../../utils/types';
import { getTimezone } from '../../../utils/time';

export const getSupportedFileFormats = async (folder: AttachmentFolder) => {
  const response = await globalGetService(
    `csp/attachments/campaignFolder/${folder}/supportedFileFormats`
  );
  if (response.ok) {
    return response.data as FileFormats;
  }
};

export const uploadAttachment = async (
  folder: AttachmentFolder,
  file: File
) => {
  const data = new FormData();
  data.append('folderType', folder);
  data.append('file', file);
  const response = await globalPostService('csp/attachments', data);
  return response.ok ? (response.data as Attachment) : undefined;
};

export const uploadAttachmentForCampaign = async (
  campaignUid: string,
  folder: AttachmentFolder,
  file: File
) => {
  const data = new FormData();
  data.append('file', file);
  const response = await globalPostService(
    `csp/attachments/campaign/${campaignUid}/folder/${folder}`,
    data
  );
  return response.ok ? (response.data as Attachment) : undefined;
};

export const getAttachmentsForCampaign = async (
  campaignUid: string,
  folder: AttachmentFolder
) => {
  const response = await globalGetService(
    `csp/attachments/campaign/${campaignUid}?folder=${encodeURIComponent(
      folder
    )}`
  );
  return response.ok ? (response.data as Attachment[]) : undefined;
};

export const getAttachment = async (attachmentUuid: string) => {
  const response = await globalGetService(
    `csp/attachments/download?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`,
    {},
    'arraybuffer'
  );
  return response.ok ? (response.data as ArrayBuffer) : undefined;
};

export const deleteAttachment = async (attachmentUuid: string) => {
  const response = await globalDeleteService(
    `csp/attachments/delete?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`
  );
  return response.ok ? attachmentUuid : undefined;
};

export const updateCampaign = async (
  campaignUid: string,
  data: Record<string, any>
) => {
  const response = await globalPutService(`csp/campaigns/${campaignUid}`, data);
  return response.ok ? (response.data as CspCampaign) : undefined;
};

export const getAllUsecasesTypes = async () => {
  const response = await globalGetService('enums/usecaseTypes');
  return response?.ok ? (response.data as UsecaseType[]) : [];
};

export const fetchBrandSuggestions = async (query = {}) => {
  const response = await globalGetService('csp/brands/suggestions', {
    ...query,
    limit: 20,
  });
  return response?.ok ? (response.data as BrandSuggestion[]) : [];
};

export const getCampaigns = async (query = {}) => {
  const response = await globalGetService('csp/campaigns', query);
  return response?.ok ? (response.data as Pagination<CspCampaign>) : undefined;
};

export const fetchResellerSuggestions = async (query = {}) => {
  const response = await globalGetService('csp/reseller/suggestion', {
    ...query,
    limit: 20,
  });
  return response?.ok ? (response.data as ResellerSuggestion[]) : [];
};

export const downloadCampaignsAsCsv = async (
  query = {}
): Promise<ArrayBuffer | undefined> => {
  const response = await globalGetService(
    'csp/campaigns/csv',
    {
      ...query,
      timezone: getTimezone(),
    },
    'arraybuffer'
  );

  return response?.ok ? (response.data as ArrayBuffer) : undefined;
};

export const getCnpList = async (query = {}) => {
  const response = await globalGetService('csp/cnp', query);
  return response?.ok ? (response.data as Cnp[]) : [];
};

export const getPartnerCampaigns = async (query = {}) => {
  const response = await globalGetService('csp/campaigns/sharedWithMe', query);
  return response?.ok
    ? (response.data as Pagination<PartnerCampaign>)
    : undefined;
};

export const downloadCnpCampaignsAsCsv = async (
  query = {}
): Promise<ArrayBuffer | undefined> => {
  const response = await globalGetService(
    'csp/campaigns/sharedWithMe/csv',
    {
      ...query,
      timezone: getTimezone(),
    },
    'arraybuffer'
  );

  return response?.ok ? (response.data as ArrayBuffer) : undefined;
};

export const getUseCaseGreyOutFields = async (
  brandId: string,
  useCase: string
) => {
  const response = await globalGetService(
    `csp/campaign/builder/brand/${brandId}/usecase/${useCase}`
  );
  return response?.ok && Array.isArray(response.data?.greyoutFields)
    ? response.data?.greyoutFields
    : [];
};

export const getMnoList = async () => {
  const response = await globalGetService('enums/mno');
  return response?.ok ? (response.data as Mno[]) : [];
};
