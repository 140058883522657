import { BoxV2 as Box, Button, Flex, RoleGuard } from 'portal-commons';
import { FunctionComponent } from 'react';
import {
  faCircleUser,
  faInfo,
  faLockKeyhole,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UserTable from './UserTable';
import { User } from '../types';
import { CustomSwitch, CustomTooltip } from '../../../../shared_elements';
import Section from '../../../../shared_elements/components/Section';

interface UserManagementSectionProps {
  loading?: boolean;
  mfaEnabled?: boolean;
  users?: User[];
  maxPortalUsers?: number;
  onMfaToggle?: () => void;
  onCreate?: () => void;
  onEdit?: (user: User) => void;
  onDelete?: (user: User) => void;
}

const UserManagementSection: FunctionComponent<UserManagementSectionProps> = ({
  loading = false,
  mfaEnabled,
  users = [],
  maxPortalUsers,
  onMfaToggle,
  onCreate,
  onEdit,
  onDelete,
}) => {
  const isCreateDisabled = maxPortalUsers
    ? users.length >= maxPortalUsers
    : false;

  return (
    <Section
      icon={faCircleUser}
      title="User Management"
      action={
        <RoleGuard feature="profile.addUser">
          <Button
            shape="square"
            size="small"
            onClick={onCreate}
            disabled={isCreateDisabled}
            data-testid="createUserButton"
          >
            Create New User
          </Button>
        </RoleGuard>
      }
      testId="userManagementSection"
    >
      <Flex
        sx={{
          flexDirection: 'column',
          paddingTop: '20px',
          borderTop: '1px solid #C0CCD4',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '9px',
            marginBottom: '20px',
            color: '#49535D',
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          <FontAwesomeIcon icon={faLockKeyhole} />
          <Box>Enable Multi Factor Authentication (MFA)</Box>
          <CustomTooltip
            arrow
            placement="right"
            title="Select this option to activate Multi Factor Authentication for all Users. MFA is available through Google Authenticator, Okta Verify or SMS authentication."
          >
            <FontAwesomeIcon
              icon={faInfo}
              className="tooltipIcon"
              color="#0091B3"
            />
          </CustomTooltip>
          <RoleGuard
            feature="profile.toggleMfa"
            render={(authorized) =>
              authorized ? (
                <CustomSwitch
                  testId="toggleMfaButton"
                  val={mfaEnabled}
                  handleChange={onMfaToggle}
                />
              ) : (
                <CustomTooltip
                  arrow
                  placement="right"
                  title="This action is available to Manager roles only."
                >
                  <Box sx={{ '> *': { marginRight: 0 } }}>
                    <CustomSwitch
                      testId="toggleMfaButton"
                      disabled
                      val={mfaEnabled}
                    />
                  </Box>
                </CustomTooltip>
              )
            }
          />
        </Flex>
        <UserTable
          loading={loading}
          users={users}
          onEdit={onEdit}
          onDelete={onDelete}
        />
        {isCreateDisabled && (
          <Box
            sx={{
              mt: 'l',
              textAlign: 'center',
              fontSize: 's',
              fontWeight: 400,
            }}
          >
            Maximum number of users reached!
            <br />
            Please delete existing users if you wish to create a new one.
          </Box>
        )}
      </Flex>
    </Section>
  );
};

export default UserManagementSection;
