import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import countryList from 'react-select-country-list';
import { getBrands, getBrandIdentityStatus } from '../apis';
import { fetchBrandSuggestions, getMnoList } from '../../Campaigns/apis';
import { BrandFilterErrorCode as FilterErrorCode } from '../../index';
import { S3_ASSETS_PATH, ENTITY_TYPES } from '../../../../constants';
import { fieldValidation } from '../../../../utils/validator';
import { AuthenticationPlusCompliantOptions } from '../../../../constants/options';
import { BrandListingRow } from '../components';
import Loader from '../../../../shared_elements/containers/Loader';
import {
  Button,
  Table,
  TableFilter,
  RoleGuard,
  FilterType,
  FilterConfig,
  WithFiltersConfig,
} from 'portal-commons';
import { withFilters } from '../../../../hocs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import '../../../../assets/styles/brand-listing-module.scss';
import {
  AdditionalFilters,
  CombinedFilters,
  DEFAULT_ADDITIONAL_FILTERS,
  Mno,
  Pagination,
  SelectItem,
} from '../../../../utils/types';
import { BrandDetail } from '../types';

const countryOptions = countryList()
  .getData()
  .filter(
    (country: { value: string; label: string }) =>
      !['US', 'CU', 'IR', 'KP', 'SY'].includes(country.value)
  );
countryOptions.unshift({ value: 'US', label: 'United States' });

const FILTER_CONFIG = {
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  displayName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Enter brand name',
    suggestions: [],
    width: 260,
  },
  country: {
    type: FilterType.Dropdown,
    label: 'Country',
    placeholder: 'Select country',
    options: countryOptions,
    width: 230,
  },
  entityType: {
    type: FilterType.Dropdown,
    label: 'Entity Type',
    placeholder: 'Select entity type',
    options: ENTITY_TYPES,
    width: 230,
  },
  ein: {
    type: FilterType.Text,
    label: 'EIN/Tax number',
    placeholder: 'Enter EIN/Tax number',
    width: 150,
  },
  universalEin: {
    type: FilterType.Text,
    label: 'Universal EIN',
    placeholder: 'Enter Universal EIN',
    width: 150,
  },
  identityStatus: {
    type: FilterType.Dropdown,
    label: 'Identity Status',
    placeholder: 'Select identity status',
    options: [],
    width: 230,
  },
  referenceId: {
    type: FilterType.Text,
    label: 'Reference ID',
    placeholder: 'Enter reference id',
    width: 150,
    error: false,
    helperText: '',
  },
  authenticationPlusCompliant: {
    type: FilterType.Dropdown,
    label: 'Auth+ Compliant',
    placeholder: 'Select auth+ compliant',
    options: AuthenticationPlusCompliantOptions,
    width: 230,
  },
};

const headRows = [
  { id: 'displayName', label: 'BRAND NAME', sortable: true },
  { id: 'uid', label: 'BRAND ID', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'entityType', label: 'ENTITY TYPE', sortable: false },
  { id: 'country', label: 'COUNTRY', sortable: true },
  {
    id: 'authenticationPlusCompliant',
    label: 'AUTH+ COMPLIANT',
    sortable: false,
  },
  { id: 'identityStatus', label: 'IDENTITY STATUS', sortable: false },
  { id: 'brandSuspended', label: 'BRAND SUSPENDED', sortable: false },
];

const useStyles = makeStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
    fontSize: '27px',
    lineHeight: '32px',
    color: '#19262A',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
  },
  spacing: {
    flex: 1,
  },
});

export interface ErrorCodeType {
  [name: string]: {
    [key: number | string]: string | boolean | number;
  };
}

const EmptyPaginationData: Pagination<BrandDetail> = {
  records: [],
  page: 1,
  recordsPerPage: 10,
  totalRecords: 0,
};

const fetchBrandNameSuggestions = async (query = {}) => {
  const suggestions = await fetchBrandSuggestions(query);
  return suggestions.map((suggestion) => suggestion.brandName);
};

interface Props extends WithFiltersConfig {
  filter: {
    configs: Record<string, FilterConfig>;
    candidateFilters: Record<string, string>;
    appliedFilters: Record<string, string>;
    handleEdit: (value: Record<string, string>) => void;
    handleApply: (value: Record<string, string>) => void;
    setOptions: (field: string, options: SelectItem[]) => void;
    updateConfigObject: (newConfigs: Record<string, FilterConfig>) => void;
  };
}

const BrandList: React.FC<Props> = ({ filter }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(true);
  const [brandInfo, setBrandInfo] =
    useState<Pagination<BrandDetail>>(EmptyPaginationData);
  const [noBrandsFound, setNoBrandsFound] = useState(false);
  const [filtersShown, setFiltersShown] = useState(false);
  const [mnos, setMnos] = useState<Mno[]>([]);
  const [additionalFilters, setAdditionalFilters] = useState<AdditionalFilters>(
    DEFAULT_ADDITIONAL_FILTERS
  );

  const fetchFilterOptions = async () => {
    const { setOptions } = filter;
    const identityStatus = await getBrandIdentityStatus();
    setOptions(
      'identityStatus',
      identityStatus.map((status) => ({ label: status, value: status }))
    );
  };

  const initialize = async () => {
    const mnoList = await getMnoList();
    mnoList.sort((a, b) => {
      if (a.osrBitmaskIndex > b.osrBitmaskIndex) {
        return 1;
      } else if (a.osrBitmaskIndex < b.osrBitmaskIndex) {
        return -1;
      }
      return 0;
    });
    setMnos(mnoList);
  };

  useEffect(() => {
    document.title = 'The Campaign Registry - Brands';
    fetchFilterOptions();
    initialize();
  }, []);

  useEffect(() => {
    if (location.search) {
      const params = parseSearchParams(location.search);
      fetchBrandList(params);
    } else {
      fetchBrandList({ ...additionalFilters });
    }
  }, [location.search]);

  const parseSearchParams = (params = ''): CombinedFilters => {
    let newAdditionalFilters = { ...additionalFilters };
    const { appliedFilters } = filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      newAdditionalFilters = DEFAULT_ADDITIONAL_FILTERS;
    } else if (queries.sortField || queries.ascendingOrder) {
      newAdditionalFilters = {
        sortField: queries.sortField as string,
        ascendingOrder: JSON.parse(queries.ascendingOrder as string),
      };
    }

    setAdditionalFilters(newAdditionalFilters);

    return {
      ...newAdditionalFilters,
      ...appliedFilters,
      page: (queries?.page as string) ?? 1,
    };
  };

  const fetchBrandList = async (params: CombinedFilters) => {
    setTableLoader(true);
    const { appliedFilters } = filter;
    const brands = await getBrands({ ...params, status: 'ACTIVE' });
    if (brands) {
      setLoader(false);
      setBrandInfo(brands);
      setNoBrandsFound(
        brands.totalRecords === 0 && Object.keys(appliedFilters).length === 0
      );

      if (
        brands.records.length === 0 &&
        brands.totalRecords > 0 &&
        brands.page > 1
      ) {
        const lastPageNo = Math.ceil(
          brands.totalRecords / brands.recordsPerPage
        );
        setLoader(true);
        writeQueryString({ ...params, page: lastPageNo });
        return;
      }
    }
    setTableLoader(false);
  };

  const handleChangePage = (newPage: number) => {
    const { appliedFilters } = filter;
    writeQueryString({
      ...appliedFilters,
      ...additionalFilters,
      page: newPage,
    });
  };

  const createSortHandler = (sortField: string, ascendingOrder: boolean) => {
    const { appliedFilters } = filter;
    const updatedAdditionalFilters = {
      ...additionalFilters,
      ascendingOrder,
      sortField,
    };
    setAdditionalFilters(updatedAdditionalFilters);

    if (brandInfo.totalRecords) {
      writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: brandInfo.page,
        sortField,
        ascendingOrder,
      });
    }
  };

  const resetFilterConfigsError = (newValues: Record<string, string>) => {
    const { configs, candidateFilters, updateConfigObject } = filter;
    const tempFilterConfigs = { ...configs };
    Object.keys(newValues).forEach((key) => {
      if (
        tempFilterConfigs[key]?.error &&
        newValues[key] !== candidateFilters[key]
      ) {
        tempFilterConfigs[key].error = false;
        tempFilterConfigs[key].helperText = '';
      }
    });
    updateConfigObject(tempFilterConfigs);
  };

  const validateFilters = (values: Record<string, string>) => {
    const { configs, updateConfigObject } = filter;
    const tempFilterConfigs = { ...configs };
    Object.keys(values).forEach((key) => {
      if (key in FilterErrorCode) {
        const code = fieldValidation({
          ...(FilterErrorCode as ErrorCodeType)[`${key}Obj`],
          fieldval: values[key],
        });
        if (code !== 0) {
          tempFilterConfigs[key].error = true;
          tempFilterConfigs[key].helperText = String(
            (FilterErrorCode as ErrorCodeType)[key][code]
          );
        }
      }
    });
    updateConfigObject(tempFilterConfigs);
    return Object.keys(values).every((key) => !tempFilterConfigs[key].error);
  };

  const handleCandidateFiltersChange = (values: Record<string, string>) => {
    resetFilterConfigsError(values);
    filter.handleEdit(values);
  };

  const handleAppliedFiltersChange = (values: Record<string, string>) => {
    if (!validateFilters(values)) {
      return;
    }

    if (Object.keys(values).length === 0) {
      resetFilterConfigsError(
        filter.configs as unknown as Record<string, string>
      );
    }
    filter.handleApply(values);
  };

  const writeQueryString = (searchParams: CombinedFilters) => {
    history.push({
      search: `?${queryString.stringify(searchParams, { encode: true })}`,
    });
  };

  return (
    <section className="brand-listing-section" style={{ padding: '0px' }}>
      {loader ? (
        <Loader />
      ) : noBrandsFound ? (
        <Container maxWidth={false} style={{ padding: 0 }}>
          <Grid
            container
            className="no-brands-found"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className="text-center">
              <div className="outer-wrapper">
                <img
                  src={`${S3_ASSETS_PATH}/images/no-campaigns-found.svg`}
                  alt=""
                />
                <h3 className="heading1">Please register a Brand</h3>
                <p className="heading1">No Brands found</p>
              </div>
              <RoleGuard feature="brandList.addBrand">
                <div className="bottom-blk">
                  <Link
                    to="/brand/create"
                    className="primary-btn"
                    data-testid="brandListRegisterBrandLink"
                  >
                    Register a Brand
                  </Link>
                </div>
              </RoleGuard>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container maxWidth={false} style={{ padding: '0px' }}>
          <div className={classes.title}>
            <span>
              {!loader ? `${brandInfo.totalRecords} Brands` : 'Brands'}
            </span>
          </div>
          <div className={classes.buttons}>
            <Button
              color="secondary"
              onClick={() => setFiltersShown(!filtersShown)}
              data-testid="tableAddFilterButton"
            >
              <FontAwesomeIcon icon={faFilter} />
              <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
            </Button>
            <div className={classes.spacing} />
            <RoleGuard feature="brandList.addBrand">
              <Button
                variant="outline"
                onClick={() => history.push('brand/create')}
                data-testid="brandListCreateBrandLink"
              >
                <FontAwesomeIcon icon={faPlus} />
                Add Brand
              </Button>
            </RoleGuard>
          </div>
          {filtersShown && (
            <Grid container style={{ marginBottom: 12 }}>
              <TableFilter
                configs={filter.configs}
                candidateValues={filter.candidateFilters}
                appliedValues={filter.appliedFilters}
                onCandidateValuesChange={(values: Record<string, string>) =>
                  handleCandidateFiltersChange(values)
                }
                onAppliedValuesChange={(values: Record<string, string>) =>
                  handleAppliedFiltersChange(values)
                }
              />
            </Grid>
          )}
          <Grid container justifyContent="center" spacing={0}>
            <Table
              testId="brandListTable"
              headRows={headRows}
              emptyState="no brands to view"
              className="brands-listing-table"
              loading={tableLoader}
              tableData={brandInfo.records.map((record) => (
                <BrandListingRow key={record.uid} data={record} mnos={mnos} />
              ))}
              handleChangePage={handleChangePage}
              createSortHandler={createSortHandler}
              filter={additionalFilters as CombinedFilters}
              pagination={{
                page: brandInfo.page,
                rowsPerPage: brandInfo.recordsPerPage,
                totalRecords: brandInfo.totalRecords,
              }}
            />
          </Grid>
        </Container>
      )}
    </section>
  );
};

export default withFilters(BrandList, {
  configs: FILTER_CONFIG,
  loaders: {
    suggestion: {
      displayName: {
        minLength: 2,
        load: (value: string) => fetchBrandNameSuggestions({ prefix: value }),
      },
    },
  },
});
