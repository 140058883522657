import { capitalize } from 'lodash';
import { getCampaignRoles, getMigrationCNP } from './apiServices';

export const charCounter = ({ value, maxLength }) => {
  let count = 0;
  if (value && value.length) {
    count = value.length;
  }
  if (maxLength) {
    return `${count} / ${maxLength}`;
  }
};

export const remapTCRStatus = (status, shouldCapitalize) => {
  if (status) {
    const value = status === 'EXPIRED' ? 'DEACTIVATED' : status;
    return shouldCapitalize ? capitalize(value) : value;
  }
  return 'N/A';
};

const isValidCampaignRole = (role) => {
  return role && !['NONE', 'UNSET'].includes(role);
};

export const getMigrationAndProvisionalInfo = async (campaignUid) => {
  const [migrationInfo = {}, roles = {}] = await Promise.all([
    getMigrationCNP(campaignUid, null, {
      silent: true,
    }),
    getCampaignRoles(campaignUid),
  ]);

  const provisional =
    migrationInfo?.status === 'ACTIVE' &&
    isValidCampaignRole(roles?.provisionalRole);

  const isAcceptedByMe = provisional
    ? roles.provisionalRoleAccepted
    : roles.normalRoleAccepted;

  return {
    migrationInfo,
    provisional,
    isAcceptedByMe,
  };
};

// this is to work with GET campaign/builder/brand/${brandId}/usecase/${useCase}
export const isFieldGreyOut = (greyOutFields = [], fieldName) => {
  if (fieldName === 'sampleMessage') {
    return greyOutFields.some((field) => field.match(/^sample[1-5]$/));
  }
  return fieldName ? greyOutFields.includes(fieldName) : false;
};
