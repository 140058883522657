import React, { useState, useRef } from 'react';
import { Container, Grid, makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { BoxV2 as Box } from 'portal-commons';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '74px',
    maxHeight: '74px',
    padding: '15px 10px',
    background: '#FFFFFF',
    overflowY: 'auto',
  },
  content: {
    width: '100%',
    height: '100%',
  },
  keyword: {
    padding: '1px 2px',
    marginRight: '10px',
    marginBottom: '15px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
    color: '#68737A',
    background: 'rgba(0, 145, 179, 0.2)',
    cursor: 'pointer',
    wordBreak: 'break-all',
  },
  input: {
    width: '100%',
    padding: '0',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
  },
}));

const SUBMIT_KEYS = ['Enter', ' ', 'Tab'];

const KeywordsInput = ({ placeholder, value, onValueChange }) => {
  const [text, setText] = useState('');
  const containerRef = useRef(null);
  const classes = useStyles();
  const keywordLength =
    Array.isArray(value) && value.length > 0 ? value.join(',').length + 1 : 0;
  const maxLength = Math.max(255 - keywordLength, 0);

  const handleKeywordDelete = (index) => {
    if (index < value.length) {
      const newValue = [...value];
      newValue.splice(index, 1);
      onValueChange && onValueChange(newValue);
    }
  };

  const submit = () => {
    if (text) {
      const newValue = value
        ? [...value, text.toUpperCase()]
        : [text.toUpperCase()];
      onValueChange && onValueChange(newValue);
      setText('');
      setTimeout(() => {
        containerRef.current &&
          containerRef.current?.scrollTo(0, containerRef.current.clientHeight);
      }, 0);
    }
  };

  const handleKeyDown = (event) => {
    const { key } = event;

    if (SUBMIT_KEYS.includes(key)) {
      event.preventDefault();
      submit();
    }

    // Allow alphanumeric characters, backspace, left arrow, and right arrow
    const isValidInput =
      /^[a-zA-Z0-9]$/.test(key) ||
      key === 'Backspace' ||
      key === 'ArrowLeft' ||
      key === 'ArrowRight';

    if (!isValidInput) {
      event.preventDefault();
    }
  };

  const handleTextChange = (event) => {
    setText(event.target.value.replace(/[^A-Za-z0-9]/g, ''));
  };

  return (
    <Container classes={{ root: classes.container }} ref={containerRef}>
      <Grid
        classes={{ root: classes.content }}
        container
        direction="column"
        alignItems="flex-start"
      >
        <Grid container direction="row" alignItems="center">
          {value &&
            value.map((text, index) => (
              <span
                key={index}
                className={classes.keyword}
                onClick={() => handleKeywordDelete(index)}
              >
                {text}
              </span>
            ))}
        </Grid>
        {maxLength > 0 ? (
          <TextField
            style={{ width: '100%' }}
            InputProps={{ disableUnderline: true }}
            inputProps={{ className: classes.input, maxLength }}
            placeholder={placeholder}
            value={text}
            onKeyDown={handleKeyDown}
            onChange={handleTextChange}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              fontSize: 'H200',
              lineHeight: 'normal',
              cursor: 'not-allowed',
            }}
          >
            Maximum keywords length reached
          </Box>
        )}
      </Grid>
    </Container>
  );
};

KeywordsInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onValueChange: PropTypes.func,
};

export default KeywordsInput;
