import React, { Component } from 'react';
import { Grid, Container, Tabs, Tab, Typography, Box } from '@material-ui/core';
import DocSummaryNew from '../components/DocSummaryNew';
import { connect } from 'react-redux';
import { ALPHABETS } from '../../../../constants';
import { checkDisplayStatus } from '../utils';
import parse from 'html-react-parser';
import '../../../../assets/styles/knowledgeBase-module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      data-testid="knowledgeBaseTabPanel"
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

class KnowledgeBaseNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      outerFrameHeight: 0,
    };
    this.myRef = React.createRef();
  }

  handleTabChange = (...args) => {
    this.myRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.setState({
      activeTab: args[1],
    });
    this.props.history.push(`/knowledge-base?activeTab=${args[1]}`);
  };

  calculateOuterFrameHeight = () => {
    const headerHeight = document.querySelector('header')
      ? document.querySelector('header').getBoundingClientRect().height
      : 60;
    const docTabHeight = document.querySelector('.docs-tab-wrapper')
      ? document.querySelector('.docs-tab-wrapper').getBoundingClientRect()
          .height
      : 40;
    const tabPanel = document.querySelector('.tab-panel-wrapper');
    const tabPanelStyle = tabPanel
      ? getComputedStyle(document.querySelector('.tab-panel-wrapper'))
      : { paddingTop: '10px', paddingBottom: '30px' };
    const tabPanelVerticalPadding =
      parseInt(tabPanelStyle.paddingTop.replace('px', ''), 10) +
      parseInt(tabPanelStyle.paddingBottom.replace('px', ''), 10);
    const footerHeight = document.querySelector('.footer-hoc-block')
      ? document.querySelector('.footer-hoc-block').getBoundingClientRect()
          .height
      : 60;
    return headerHeight + docTabHeight + tabPanelVerticalPadding + footerHeight;
  };

  componentDidMount() {
    // debugger
    const query = new URLSearchParams(this.props.location.search);
    const activeTab = query.get('activeTab');
    if (parseInt(activeTab, 10)) {
      this.setState({
        activeTab: parseInt(activeTab, 10),
      });
    }
    this.setState({
      outerFrameHeight: this.calculateOuterFrameHeight(),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    let { hash } = location;
    hash = hash.substring(1, hash.length);
    const element = document.getElementById(`${hash}`);
    if (element) {
      element.scrollIntoView();
    }
  }

  render() {
    const { activeTab, outerFrameHeight } = this.state;
    return (
      <section className="knowledge-base-section" data-testid="knowledgeBase">
        <Container maxWidth={false} style={{ padding: '0px' }}>
          <Grid container>
            <Grid item lg={9}>
              <div className="docs-tab-wrapper">
                <Tabs
                  value={activeTab}
                  onChange={this.handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  // variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  className="knowledgebase-tab"
                  data-testid="knowledgeBaseTab"
                >
                  {this.props.knowledgeBase &&
                    this.props.knowledgeBase.map((item, index) => (
                      <Tab
                        label={item.title}
                        key={index}
                        data-testid={`knowledgeBaseTab-${item.title.replace(
                          /\s/g,
                          ''
                        )}`}
                        id={`scrollable-auto-tab-${index}`}
                      />
                    ))}
                </Tabs>
              </div>
              <div
                className="tab-panel-wrapper"
                data-testid="knowledgeBaseTabWrapper"
                ref={this.myRef}
                onScroll={this.handleScroll}
                style={{ height: window.innerHeight - outerFrameHeight }}
              >
                <TabPanel value={activeTab} index={activeTab}>
                  {this.props.knowledgeBase &&
                    this.props.knowledgeBase[activeTab] &&
                    this.props.knowledgeBase[activeTab].sections
                      .filter((item) => checkDisplayStatus(item.status))
                      .map((item, index) => (
                        <div
                          className="section-wrapper"
                          key={`index-${index + 1}`}
                          id={`${index + 1}`}
                        >
                          <h1 className="sectionHeader">{item.title}</h1>
                          <div className="paragraph content">
                            {parse(item.text || '')}
                          </div>
                          {item.subsections && item.subsections.length > 0 ? (
                            <div className="subsectionsList">
                              {item.subsections
                                .filter((sub) => checkDisplayStatus(sub.status))
                                .map((sub, subIndex) => (
                                  <React.Fragment>
                                    <div
                                      className="subsectionTitle"
                                      id={`${index + 1}${ALPHABETS[subIndex]}`}
                                    >
                                      {sub.title}
                                    </div>
                                    <div className="content paragraph">
                                      {parse(sub.text || '')}
                                    </div>
                                  </React.Fragment>
                                ))}
                            </div>
                          ) : null}
                        </div>
                      ))}
                </TabPanel>
              </div>
            </Grid>
            <Grid item lg={3} data-testid="knowledgeBaseDocSummaryNew">
              <DocSummaryNew
                activeContent={this.props.knowledgeBase[activeTab]}
              />
            </Grid>
          </Grid>
        </Container>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  knowledgeBase: state.shareReducer.knowledgeBase,
});
export default connect(mapStateToProps)(KnowledgeBaseNew);
