import { Button, RoleGuard } from 'portal-commons';
import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import ResellerSelectModal from './ResellerSelectModal';
import CustomizableConfirmationDialog from './CustomizableConfirmationDialog';
import { S3_ASSETS_PATH } from '../../../../constants';

export default function ReSellerDetails({
  campaignStatus,
  resellerInfo,
  loader,
  noResellerLoader,
  handleAddReseller,
}) {
  const [resellerSelectModalOpen, setResellerSelectModalOpen] = useState(false);
  const [noResellerConfirmation, setNoResellerConfirmation] = useState(false);
  const isActivated = campaignStatus === 'ACTIVE';

  const handleSetNoReseller = (flag) => {
    setNoResellerConfirmation(false);
    if (flag) {
      handleAddReseller('R000000');
    }
  };

  return (
    <Grid
      container
      spacing={2}
      className="reseller-container"
      data-testid="resellerDetails"
    >
      <Grid item xs={6}>
        <div
          className="flex-centered title"
          data-testid="resellerDetailsResellerInvolved"
        >
          <Grid item xs={4}>
            <p className={`paragraph ${resellerInfo ? 'reseller' : ''}`}>
              Reseller Involved
            </p>
          </Grid>
          <Grid container xs={8} className="flex-centered">
            <Grid
              item
              xs={4}
              style={{ minWidth: '150px' }}
              data-testid="resellerDetailsResellerInvolvedInfo"
            >
              {resellerInfo ? (
                resellerInfo.uid === 'R000000' ? (
                  <div className="value">
                    <img src={`${S3_ASSETS_PATH}/images/checked-new.svg`} />
                    <span style={{ color: '#00797C' }}>No Reseller</span>
                  </div>
                ) : (
                  <div className="value">
                    <img src={`${S3_ASSETS_PATH}/images/checked-new.svg`} />
                    <span style={{ color: '#00797C' }}>Yes</span>
                  </div>
                )
              ) : (
                <div className="value">
                  <img src={`${S3_ASSETS_PATH}/images/negative-checked.svg`} />
                  <span style={{ color: '#D41C54' }}>N/A</span>
                </div>
              )}
            </Grid>
            <Grid item xs={7}>
              {loader && (
                <CircularProgress size={30} style={{ color: '#00797C' }} />
              )}
              {!resellerInfo && !loader && (
                <RoleGuard
                  feature="campaignDetail.addReseller"
                  render={(authorized) => (
                    <Button
                      data-testid="resellerDetailsResellerInvolvedAddReseller"
                      shape="square"
                      color="secondary"
                      size="small"
                      disabled={!isActivated || !authorized}
                      onClick={() => setResellerSelectModalOpen(true)}
                    >
                      <AddIcon />
                      Add a Reseller
                    </Button>
                  )}
                />
              )}
              {resellerInfo && resellerInfo.uid !== 'R000000' && !loader && (
                <RoleGuard
                  feature="campaignDetail.addReseller"
                  render={(authorized) => (
                    <Button
                      data-testid="resellerDetailsResellerInvolvedRemoveReseller"
                      shape="square"
                      color="secondary"
                      size="small"
                      disabled={!isActivated || !authorized}
                      onClick={() => setNoResellerConfirmation(true)}
                    >
                      <RemoveIcon /> Remove Reseller
                    </Button>
                  )}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={6}></Grid>
      {resellerInfo && resellerInfo.uid !== 'R000000' && (
        <React.Fragment>
          <Grid item xs={6}>
            <Grid container data-testid="resellerDetailsLegalCompanyName">
              <Grid item xs={4}>
                <p className="paragraph">Legal Company Name:</p>
              </Grid>
              <Grid item xs={8}>
                <p className="paragraph">
                  <span>{resellerInfo.companyName}</span>
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container data-testid="resellerDetailsPhoneNumber">
              <Grid item xs={4}>
                <p className="paragraph">Phone Number:</p>
              </Grid>
              <Grid item xs={8}>
                <p className="paragraph">
                  <span>{resellerInfo.phone}</span>
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container data-testid="resellerDetailsResellerId">
              <Grid item xs={4}>
                <p className="paragraph">Reseller ID:</p>
              </Grid>
              <Grid item xs={8}>
                <p className="paragraph">
                  <span>{resellerInfo.uid}</span>
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container data-testid="resellerDetailsEmailAddress">
              <Grid item xs={4}>
                <p className="paragraph">Email Address:</p>
              </Grid>
              <Grid item xs={8}>
                <p className="paragraph">
                  <span>{resellerInfo.email}</span>
                </p>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <ResellerSelectModal
        open={resellerSelectModalOpen}
        handleClose={() => setResellerSelectModalOpen(false)}
        handleSubmit={handleAddReseller}
      />
      <CustomizableConfirmationDialog
        open={noResellerConfirmation}
        content={`Please confirm that you are changing the reseller on this campaign to No Reseller.<br /><br /><b>Note:</b> Once No Reseller is specified, it cannot be changed again.`}
        submitText={'Proceed'}
        handleClick={(flag) => handleSetNoReseller(flag)}
        loader={noResellerLoader}
      />
    </Grid>
  );
}
