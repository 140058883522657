import React, { useState } from 'react';
import { Button, BoxV2 as Box, Flex } from 'portal-commons';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  MenuProps,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';

import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../../../shared_elements/actions';
import { downloadCampaignPhoneNumbers } from '../../apiServices';
import { toastFlashMessage } from '../../../../../utils';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    padding: '40px 30px 10px',
    '& img': {
      marginRight: '5px',
      position: 'relative',
      top: '4px',
      height: '20px',
    },
  },
  content: {
    margin: '10px 30px 30px',
    padding: '7px 0 10px !important',
    fontSize: '16px',
    lineHeight: 1.15,
    textAlign: 'center',
    width: '465px',
    '& .form-group-field': {
      boxSizing: 'border-box',
      '& .MuiSelect-nativeInput': {
        boxSizing: 'border-box',
      },
    },
  },
  formGroup: {
    marginBottom: '30px',
    textAlign: 'left',
    '& .MuiFormLabel-root': {
      fontSize: '14px',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },
  },
  actionBlock: {
    justifyContent: 'center',
    padding: 0,
    '& button:not(:first-child)': {
      marginLeft: '20px',
    },
  },
});

interface Props {
  open: boolean;
  handleClose: () => void;
  campaignUid: string;
}

const DownloadPhoneNumbersModal: React.FC<Props> = ({
  open,
  handleClose,
  campaignUid,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectMenuProps: Partial<MenuProps> = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };
  const [downloadState, setDownloadState] = useState<string>('');

  const setLoadingMessage = (message: string) => {
    dispatch({
      type: SET_LOADING_MESSAGE,
      payload: message,
    });
  };

  const clearLoadingMessage = () => {
    dispatch({
      type: CLEAR_LOADING_MESSAGE,
      payload: '',
    });
  };

  const handleDownloadPhoneNumbers = async () => {
    handleCloseModal();
    setLoadingMessage('We are generating the document');
    const query = {
      current: downloadState === 'current',
    };
    const response = await downloadCampaignPhoneNumbers(campaignUid, query);
    if (response) {
      const file = new File([response], 'phone-numbers.csv');
      const url = URL.createObjectURL(file);
      const element = document.createElement('a');
      element.href = url;
      element.download = file.name;
      document.body.appendChild(element);
      element.click();
      toastFlashMessage('Download Successful', 'success');
    }
    clearLoadingMessage();
  };

  const handleCloseModal = () => {
    setDownloadState('');
    handleClose();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="Download Phone Numbers"
      data-testid="downloadPhoneNumbersModal"
    >
      <DialogTitle className={classes.title}>
        <Flex sx={{ justifyContent: 'center' }}>
          <Box sx={{ mr: 's' }}>
            <FontAwesomeIcon icon={faArrowDownToLine} />
          </Box>
          Download
        </Flex>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: 1.2,
            textAlign: 'left',
            marginBottom: '15px',
            color: 't.grey500',
          }}
        >
          <p>
            To download a complete list of current or previous phone numbers
            please use the drop down below then click download.
          </p>
          <Box
            as="p"
            sx={{
              margin: '15px 0',
              fontSize: '10px',
            }}
          >
            * Indicates a Required Field
          </Box>
        </Box>
        <div className={`${classes.formGroup} form-group-field`}>
          <FormControl
            required
            style={{ width: '100%' }}
            data-testid="currentOrPreviousNumberField"
          >
            <InputLabel>Select Current or Previous Numbers</InputLabel>
            <Select
              MenuProps={selectMenuProps}
              value={downloadState}
              fullWidth={true}
              inputProps={{
                'data-testid': 'currentOrPreviousNumberSelect',
              }}
              onChange={(event) =>
                setDownloadState(event.target.value as string)
              }
            >
              <MenuItem key="current" value="current">
                Current Phone Numbers
              </MenuItem>
              <MenuItem key="previous" value="previous">
                Previous Phone Numbers
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <DialogActions className={classes.actionBlock}>
          <Button
            variant="outline"
            onClick={handleCloseModal}
            data-testid="downloadPhoneNumbersCancelButton"
          >
            Cancel
          </Button>
          <Button
            disabled={downloadState === ''}
            onClick={handleDownloadPhoneNumbers}
            data-testid="downloadPhoneNumberDownloadButton"
          >
            Download
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadPhoneNumbersModal;
