export type EmptyString = '';

export interface Pagination<T> {
  totalRecords: number;
  records: Array<T>;
  page: number;
  recordsPerPage: number;
  metaData?: Record<string, string | number> | null;
}

export interface SelectItem {
  label: string;
  value: string;
}

export interface AdditionalFilters {
  sortField: string;
  ascendingOrder: boolean;
  page?: string | number;
}
export interface AppliedFilters {
  [key: string]: string | boolean | number | undefined;
}
export type CombinedFilters = AdditionalFilters & AppliedFilters;

export const DEFAULT_ADDITIONAL_FILTERS = {
  sortField: 'createDate',
  ascendingOrder: false,
};

export interface UsecaseType {
  id: string;
  classification: string;
  displayName: string;
  description: string;
  monthlyFee: number;
  quarterlyFee: number;
  annualFee: number;
  validSubUsecase: boolean;
  minSubUsecases: number;
  maxSubUsecases: number;
  maxTn: number;
}

export interface Cnp {
  uid: string;
  displayName: string;
}

export interface Mno {
  networkId: number;
  displayName: string;
  osrBitmaskIndex: number;
}
