export enum Role {
  Viewer = 'ROLE_VIEWER',
  User = 'ROLE_USER',
  Manager = 'ROLE_MANAGER',
  Reviewer = 'ROLE_REVIEWER',
}

export type User = {
  id: string;
  status: string;
  created: number;
  statusChanged: number;
  lastLogin?: number;
  lastUpdated: number;
  profile: {
    firstName: string;
    lastName: string;
    email: string;
    mobilePhone?: string;
    companyName?: string;
    companyWebsite?: string;
    cspUid: string;
    csp_app_role?: Role[];
    fullName: string;
  };
};

export type CspProfile = {
  uid: string;
  displayName: string;
  status: string;
  escalateEmail: string;
  maxBrands: number;
  maxCampaignsPerBrand: number;
  companyName: string;
  ein: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  email: string;
  techEmail: string;
  financeEmail: string;
  stockSymbol: string;
  stockExchange: string;
  website: string;
  entityType: string;
  activationFeePaid: boolean;
  platformFreeTrialEnabled: boolean;
  soleProprietorEnabled: boolean;
  ucaasEnabled: boolean;
  publicSafetyEnabled: boolean;
  mfaEnabled: boolean;
  maxPortalUsers: number;
  dpaSigned: boolean;
  createDate: number;
};
