import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../constants';
import { campaignErrorCode } from '../../index';
// import ToolTip from '../../../../ui_elements/ToolTip';
import { fieldValidation } from '../../../../utils/validator';
import EditSampleMessages from './EditSampleMessages';
import { isValidInput } from '../../../../utils';
import EditSampleMultimedia from './EditSampleMultimedia';
import {
  deleteAttachmentApi,
  downloadAttachmentApi,
  uploadAttachmentForCampaignApi,
  downloadAttachmentToDesktop,
} from '../apiServices';
import { ATTACHMENT_FOLDER_MMS } from '../constants';
import { charCounter } from '../utils';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMessageDots,
  faChartSimpleHorizontal,
} from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  description: {
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
    },
  },
  messageFlow: {
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
    },
  },
});

const CampaignEditModal = (props) => {
  const { type, campaignInfo, open, loader } = props;
  const [campaign, setCampaign] = useState(campaignInfo);
  const [sampleMessage, setSampleMessage] = useState({});
  const [description, setDescription] = useState('');
  const [messageFlow, setMessageFlow] = useState('');
  const [error, setError] = useState({});
  const [errorCode] = useState(campaignErrorCode);
  const [sampleMessageCount, setSampleMessageCount] = useState('');
  const [sampleMultimedia, setSampleMultimedia] = useState([]);
  const [reverting, setReverting] = useState(false);
  const classes = useStyles();

  const handleDeleteSampleMessage = (key) => {
    const sampleMessageObject = {
      ...sampleMessage,
    };
    const sampleMessageError = {
      ...error,
    };
    if (sampleMessageObject.hasOwnProperty(key)) {
      delete sampleMessageObject[key];
      delete sampleMessageError[key];
      Object.keys(sampleMessageObject).forEach((key, index) => {
        if (key !== `sample${index + 1}`) {
          sampleMessageObject[`sample${index + 1}`] = sampleMessageObject[key];
          delete sampleMessageObject[key];
          if (sampleMessageError[key]) {
            sampleMessageError[`sample${index + 1}`] = sampleMessageError[key];
            delete sampleMessageError[key];
          }
        }
      });
      setSampleMessage(sampleMessageObject);
      setError(sampleMessageError);
    }
  };
  const handleMessageTextChange = (value, key) => {
    setSampleMessage({ ...sampleMessage, [key]: value });
    setError({ ...error, [key]: '' });
  };
  const handleAddSampleMessage = () => {
    const sampleMessageCount = Object.keys(sampleMessage).length;
    if (sampleMessageCount >= 0 && sampleMessageCount < 5) {
      setSampleMessage({
        ...sampleMessage,
        [`sample${sampleMessageCount + 1}`]: '',
      });
      setError({ ...error, [`sample${sampleMessageCount + 1}`]: '' });
    }
  };
  const handleSampleMultimediaAdd = async (file) => {
    if (sampleMultimedia.length < 5) {
      const response = await uploadAttachmentForCampaignApi(
        campaignInfo.uid,
        ATTACHMENT_FOLDER_MMS,
        file
      );
      setSampleMultimedia([
        ...sampleMultimedia,
        {
          file,
          ...response,
        },
      ]);
    }
  };
  const handleSampleMultimediaDelete = async (index) => {
    await deleteAttachmentApi(sampleMultimedia[index].uuid);
    const newSampleMultimedia = [...sampleMultimedia];
    newSampleMultimedia.splice(index);
    setSampleMultimedia(newSampleMultimedia);
  };
  const handleSampleMultimediaSelect = async (index) => {
    if (sampleMultimedia[index] && !sampleMultimedia[index].file) {
      const response = await downloadAttachmentApi(
        sampleMultimedia[index].uuid
      );
      const newSampleMultimedia = [...sampleMultimedia];
      newSampleMultimedia.splice(index, 1, {
        ...sampleMultimedia[index],
        file: new File([response], sampleMultimedia[index].fileName, {
          type: sampleMultimedia[index].mimeType,
        }),
      });
      setSampleMultimedia(newSampleMultimedia);
    }
  };

  const handleSampleMultimediaDownload = async (index) => {
    await downloadAttachmentToDesktop(
      sampleMultimedia[index].uuid,
      sampleMultimedia[index].fileName
    );
  };

  useEffect(() => {
    setCampaign(campaignInfo);
    setError({});
    if (open) {
      if (type === 'multimedia') {
        setSampleMultimedia([
          ...props.sampleMultimedia.map((item) => ({
            ...item,
            readonly: true,
          })),
        ]);
      } else if (type === 'messages') {
        const sampleMessage = {};
        const sample = [];
        Object.keys(campaignInfo).forEach((k) => {
          if (k.includes('sample') && campaignInfo[k]) {
            sample.push(campaignInfo[k]);
          }
        });
        sample.forEach((item, index) => {
          sampleMessage[`sample${index + 1}`] = item;
        });
        setSampleMessage(sampleMessage);
        setSampleMessageCount(Object.keys(sampleMessage).length);
      } else if (type === 'description') {
        setDescription(campaignInfo.description);
        setMessageFlow(campaignInfo.messageFlow);
      }
    }
  }, [open]);

  const handleCancel = async (e) => {
    e.preventDefault();
    if (type === 'multimedia') {
      setReverting(true);
      for (const multimedia of sampleMultimedia) {
        if (!multimedia.readonly) {
          await deleteAttachmentApi(multimedia.uuid);
        }
      }
      setReverting(false);
    }
    props.handleClose();
  };

  const validateField = (value, key) => {
    return errorCode[key][
      fieldValidation({ ...errorCode[`${key}Obj`], fieldval: value })
    ];
  };

  const handleError = (value, key) => {
    setError({
      ...error,
      [key]: validateField(value, key),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === 'multimedia') {
      props.handleSubmitEdit({ sampleMultimedia });
    } else if (type === 'messages') {
      const validateNewInput = {};
      Object.keys(sampleMessage).forEach((k) => {
        validateNewInput[k] = validateField(sampleMessage[k], k);
      });
      if (
        Object.keys(validateNewInput).every((k) => validateNewInput[k] === '')
      ) {
        const data = {};
        Object.keys(sampleMessage).forEach((k) => {
          if (sampleMessage[k]) {
            data[k] = sampleMessage[k];
          }
        });
        props.handleSubmitEdit(data);
      } else {
        setError(validateNewInput);
      }
    } else if (type === 'description') {
      const validateNewInput = {
        description: validateField(description, 'description'),
        messageFlow: validateField(messageFlow, 'messageFlow'),
      };
      if (
        Object.keys(validateNewInput).every((k) => validateNewInput[k] === '')
      ) {
        props.handleSubmitEdit({
          description,
          messageFlow,
        });
      } else {
        setError(validateNewInput);
      }
    }
  };
  return (
    <Dialog
      open={open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={type == 'name' ? 'xs' : 'md'}
      fullWidth={true}
      id="successModal"
      className={`edit-modal campaign`}
      disableBackdropClick
      data-testid="campaginEditModal"
    >
      <DialogContent className={`edit-content campaign`}>
        <Grid container>
          {type == 'messages' ? (
            <div className="title">
              <Box flexDirection="row" alignItems="center">
                <Box margin={{ right: 'xs' }}>
                  <FontAwesomeIcon icon={faMessageDots} size="xl" />
                </Box>
                <h3 className="heading1">Sample Messages</h3>
              </Box>
            </div>
          ) : null}
          {type == 'description' ? (
            <div className="title description-title">
              <Box flexDirection="row" alignItems="center">
                <Box margin={{ right: 'xs' }}>
                  <FontAwesomeIcon icon={faChartSimpleHorizontal} size="xl" />
                </Box>
                <h3 className="heading1">Campaign Details</h3>
              </Box>
            </div>
          ) : null}
          <form onSubmit={handleSubmit}>
            {type === 'multimedia' && (
              <EditSampleMultimedia
                sampleMultimedia={sampleMultimedia}
                onAdd={handleSampleMultimediaAdd}
                onDelete={handleSampleMultimediaDelete}
                onSelect={handleSampleMultimediaSelect}
                onDownload={handleSampleMultimediaDownload}
              />
            )}
            {type === 'messages' && (
              <EditSampleMessages
                sampleMessage={sampleMessage}
                handleChange={handleMessageTextChange}
                handleError={handleError}
                handleAddSampleMessage={handleAddSampleMessage}
                handleDeleteSampleMessage={handleDeleteSampleMessage}
                error={error}
                sampleMessageCount={sampleMessageCount}
              />
            )}
            {type === 'description' && (
              <div className="form-group-field" style={{ margin: 0 }}>
                <TextField
                  data-testid="campaignEditModalDescriptionInput"
                  classes={{ root: classes.description }}
                  inputProps={{
                    maxLength: 4096,
                    minLength: 40,
                    'aria-label': 'description',
                  }}
                  required
                  fullWidth
                  multiline
                  error={!!error.description}
                  value={description}
                  rows={2}
                  rowsMax={3}
                  id="description"
                  label="Campaign Description"
                  helperText={charCounter({
                    value: description,
                    maxLength: 4096,
                  })}
                  onChange={(event) => {
                    setDescription(event.target.value);
                    setError({
                      ...error,
                      description: '',
                    });
                  }}
                  onBlur={(event) =>
                    handleError(event.target.value, 'description')
                  }
                />
                {error.description ? (
                  <h6 className="error-msg">{error.description}</h6>
                ) : null}
                <TextField
                  classes={{ root: classes.messageFlow }}
                  data-testid="campaignEditModalMessageFlowInput"
                  style={{ marginTop: 15 }}
                  inputProps={{
                    maxLength: 4096,
                    minLength: 40,
                    'aria-label': 'messageFlow',
                  }}
                  required
                  fullWidth
                  multiline
                  error={!!error.messageFlow}
                  id="messageFlow"
                  label="Call-to-Action / Message Flow"
                  value={messageFlow}
                  rows={2}
                  rowsMax={3}
                  helperText={charCounter({
                    value: messageFlow,
                    maxLength: 4096,
                  })}
                  onChange={(event) => {
                    setMessageFlow(event.target.value);
                    setError({
                      ...error,
                      messageFlow: '',
                    });
                  }}
                  onBlur={(event) =>
                    handleError(event.target.value, 'messageFlow')
                  }
                />
                {error.messageFlow ? (
                  <h6 className="error-msg">{error.messageFlow}</h6>
                ) : null}
              </div>
            )}
          </form>
          <Grid item xs={12} className="footer">
            <li>
              <p>
                <a
                  data-testid="campaignEditModalCancelButton"
                  className={
                    reverting ? 'secondary-btn disabled' : 'secondary-btn'
                  }
                  onClick={handleCancel}
                >
                  {reverting ? 'Reverting...' : 'Cancel'}
                </a>
              </p>
            </li>
            <li>
              <p>
                <a
                  data-testid="campaignEditModalSaveButton"
                  className={
                    loader || Object.keys(error).find((k) => error[k] != '')
                      ? 'primary-btn disabled'
                      : 'primary-btn'
                  }
                  onClick={handleSubmit}
                >
                  {loader ? 'Saving...' : 'Save'}
                </a>
              </p>
            </li>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default CampaignEditModal;
