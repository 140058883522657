import React from 'react';
import BrandDetailItem from './BrandDetailItem';
import { Grid } from '@material-ui/core';
import { ENTITY_TYPES } from '../../../../constants';

const getR3kStatus = (optionalAttributes) => {
  return optionalAttributes && optionalAttributes.russell3000
    ? 'True'
    : 'False';
};

const getTaxExemptStatus = (optionalAttributes) => {
  if (optionalAttributes) {
    if (optionalAttributes.section527) {
      return '527';
    }
    if (optionalAttributes.taxExemptStatus) {
      return optionalAttributes.taxExemptStatus;
    }
  }
  return 'N/A';
};

const getGovernmentEntity = (optionalAttributes) => {
  return optionalAttributes && optionalAttributes.governmentEntity
    ? 'True'
    : 'False';
};

const getPoliticalCommitteeLocale = (optionalAttributes) => {
  return optionalAttributes && optionalAttributes.politicalCommitteeLocale
    ? optionalAttributes.politicalCommitteeLocale
    : 'N/A';
};

const BrandDetails = (props) => {
  const { brand, usecase, dpaSigned } = props;
  const isSoleProprietor = usecase === 'SOLE_PROPRIETOR';
  const isRedacted = isSoleProprietor && !dpaSigned;

  const redactDisplayValue = (displayVal) =>
    isRedacted ? 'Redacted due to PII' : displayVal;

  return (
    <Grid container data-testid="brandDetails">
      <Grid item xs={6}>
        <BrandDetailItem
          xs={5}
          title="Legal Company Name"
          value={brand.companyName}
        />
        <BrandDetailItem
          xs={5}
          title="Brand Name or DBA"
          value={redactDisplayValue(brand.displayName)}
        />
        <BrandDetailItem
          xs={5}
          title="Entity Type"
          value={
            (ENTITY_TYPES.find((entity) => entity.value === brand.entityType) &&
              ENTITY_TYPES.find((entity) => entity.value === brand.entityType)
                .label) ||
            brand.entityType
          }
        />
        <BrandDetailItem
          xs={5}
          title="External Vetting Score"
          value={
            Number.isInteger(brand.evpVettingScore)
              ? String(brand.evpVettingScore)
              : null
          }
        />
        <BrandDetailItem
          xs={5}
          title="Website/Online Presence"
          value={brand.website}
        />
        <BrandDetailItem
          xs={5}
          title="Support Phone Number"
          value={redactDisplayValue(brand.phone)}
        />
      </Grid>
      <Grid item xs={6}>
        <BrandDetailItem
          xs={5}
          title="Brand Support Email"
          value={redactDisplayValue(brand.email)}
        />
        <BrandDetailItem
          xs={5}
          title="Russell3000"
          value={getR3kStatus(brand.optionalAttributes)}
        />
        <BrandDetailItem
          xs={5}
          title="Tax Exempt Status"
          value={getTaxExemptStatus(brand.optionalAttributes)}
        />
        <BrandDetailItem
          xs={5}
          title="Government Entity"
          value={getGovernmentEntity(brand.optionalAttributes)}
        />
        <BrandDetailItem
          xs={5}
          title="Political Committee Locale"
          value={getPoliticalCommitteeLocale(brand.optionalAttributes)}
        />
      </Grid>
    </Grid>
  );
};

export default BrandDetails;
